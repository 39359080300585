module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bonobolabs/gatsby-remark-images-custom-widths/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"17 Hippies - Info","short_name":"17 Hippies Info","start_url":"/","background_color":"#feedd1","display":"minimal-ui","icon":"src/images/17H-Sonne_Rotbraun-Transp-thumb.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"16c93449e43202b1cdc34cd0a4a36613"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
